/* ============================================ */
/* Swiper (carousel), addition of Swiper lib    */
/* ============================================ */

/* Generic swiper classes (used from lib) */

.swiper {
  @apply w-full h-full;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide {
  width: 100%;
}

.swiper-pagination-bullet-active {
  @apply !bg-violet-80;
}

.product-header-slider_slide {
  @apply before:block before:w-1px before:-ml-1px;
  @apply before:pb-[55.55555556%];
  @apply xl:before:pb-[40%];
  @apply 2xl:before:pb-[35%];
  @apply 3xl:before:pb-[31.80555556%];
  @apply min-h-[20rem];
  @apply overflow-hidden;
}

.product-header-slider
  > .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
  @apply bottom-0;
}

.product-header-slider .swiper-pagination {
  @apply lg:px-20 lg:!bottom-8 lg:flex lg:justify-end lg:gap-1.5;
  @apply 2xl:px-30;
  @apply 3xl:!w-screen-3xl 3xl:!left-1/2 3xl:-translate-x-1/2;
}

.product-header-slider .swiper-pagination-bullet {
  @apply lg:!w-3 lg:!h-3 lg:!bg-white/30 lg:opacity-100;
}

.product-header-slider .swiper-pagination-bullet-active {
  @apply lg:!bg-white;
}

/* cutsomize sliders on product page
.product-header-slider .swiper-pagination {
  @apply block sm:hidden;
}

.all-in-one-gutschein-slider
  .swiper-pagination-bullets.swiper-pagination-horizontal {
  @apply bottom-0;
}

.product-header-slider .swiper-pagination-bullet-active {
  @apply bg-violet-80;
} */
