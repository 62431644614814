/* ============================================ */
/* Checkbox */
/* ============================================ */

@layer components {
  input:checked + div {
    @apply bg-violet-100 border-opacity-100;
  }

  input:hover + div {
    @apply border-opacity-60;
  }

  input:focus + div {
    @apply ring-1 ring-violet-100 ring-opacity-50 ring-offset-4;
  }

  input:checked + div svg {
    @apply block;
  }
}
