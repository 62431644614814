/* ============================================ */
/* Checkbox */
/* ============================================ */

@layer components {
  .modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3000;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .modal-container {
    position: relative;
    min-width: 320px;
    max-height: 100%;
    overflow-y: auto;
    display: flex;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 9px 12px rgba(10, 31, 68, 0.15);
  }

  :global(.ic-close) {
    position: absolute;
    top: 21px;
    right: 21px;
  }
}
