/* ============================================ */
/* Container */
/* ============================================ */

@layer components {
  .container {
    @apply max-w-screen-3xl;
    @apply mx-auto;
    @apply px-6;
    @apply sm:px-8;
    @apply lg:px-10;
    @apply 2xl:px-20;
    @apply w-full;
  }

  .container-large {
    @apply max-w-screen-4xl;
  }

  .container-confetti canvas {
    /* Necessary to bring the confetti container in front of the header menu bar (the style attributes are overriden by the lib) */
    @apply !z-50;
  }
}
