/* ============================================ */
/* Scroll Margin */
/* ============================================ */

@media print {
  /* hack to hide the header and footer which is added by the browser (not working for Safari) */
  @page {
    size: A4;
    margin: 0;
  }

  body {
    margin: 1cm;
  }
}
