/* ============================================ */
/* Grid */
/* ============================================ */

/* Row */
/* ============================================ */

@layer components {
  .g-row {
    @apply -mx-2.5;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    @apply sm:-mx-5;
  }
}

/* Unit */
/* ============================================ */

@layer components {
  .g {
    @apply px-2.5;
    width: 100%;

    @apply sm:px-5;
  }
}

/* Sizes */
/* ============================================ */

@layer components {
  .g-1 {
    width: 8.333333%;
  }

  .g-2 {
    width: 16.666667%;
  }

  .g-3 {
    width: 25%;
  }

  .g-4 {
    width: 33.333333%;
  }

  .g-5 {
    width: 41.666667%;
  }

  .g-6 {
    width: 50%;
  }

  .g-7 {
    width: 58.333333%;
  }

  .g-8 {
    width: 66.666667%;
  }

  .g-9 {
    width: 75%;
  }

  .g-10 {
    width: 83.333333%;
  }

  .g-11 {
    width: 91.666667%;
  }

  .g-12 {
    width: 100%;
  }
}

/* Offset */
/* ============================================ */

@layer components {
  .g-off-0 {
    margin-left: 0;
  }

  .g-off-1 {
    margin-left: 8.333333%;
  }

  .g-off-2 {
    margin-left: 16.666667%;
  }

  .g-off-3 {
    margin-left: 25%;
  }

  .g-off-4 {
    margin-left: 33.333333%;
  }

  .g-off-5 {
    margin-left: 41.666667%;
  }

  .g-off-6 {
    margin-left: 50%;
  }

  .g-off-7 {
    margin-left: 58.333333%;
  }

  .g-off-8 {
    margin-left: 66.666667%;
  }

  .g-off-9 {
    margin-left: 75%;
  }

  .g-off-10 {
    margin-left: 83.333333%;
  }

  .g-off-11 {
    margin-left: 91.666667%;
  }
}
