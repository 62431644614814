/* ============================================ */
/* Styles */
/* ============================================ */

/* Base */
/* ============================================ */

@import 'tailwindcss/base';
@import url('./base/fonts');
@import url('./base/base');

/* Components */
/* ============================================ */

@import 'tailwindcss/components';
@import url('./components/checkbox');
@import url('./components/container');
@import url('./components/grid');
@import url('./components/modal');
@import url('./components/slider');
@import url('./components/typography');

/* Utilities */
/* ============================================ */

@import 'tailwindcss/utilities';
@import url('./utilities/print');

/* Shame - https://media.giphy.com/media/vX9WcCiWwUF7G/giphy.gif */
/* ============================================ */

.email-input.invalid {
  @apply bg-red-100 rounded;
}
