/* ============================================ */
/* Typography */
/* ============================================ */

/* Styling for generated Text */

/* Text color */
/* ============================================ */
@layer components {
  .typography {
    @apply text-black;
    @apply text-opacity-70;
    @apply md:text-md;
  }
}

/* Vertical Rythm */
/* ============================================ */

@layer components {
  .typography ol,
  .typography ul,
  .typography p {
    &:first-child {
      margin-top: 0;
    }

    &:not(:last-child) {
      margin-bottom: 1.25em;
    }
  }
}

/* Anchors */
/* ============================================ */

@layer components {
  .typography a {
    @apply text-violet-100;
    @apply underline;
    @apply transition;
    @apply h:hover:text-opacity-50;
  }

  .typography-noColor a {
    @apply text-inherit;
  }
}

/* Lists */
/* ============================================ */

@layer components {
  .typography ol,
  .typography ul {
    & li {
      position: relative;
    }
  }

  .typography ol li ul,
  .typography ul li ol {
    @apply ml-4;
    margin-top: 0.35em;
    margin-bottom: 0;
  }

  /* Ordered */
  .typography ol {
    counter-reset: ol;
  }

  .typography ol li {
    padding-left: 1.4em;
    counter-increment: ol;
  }

  .typography ol li::before {
    position: absolute;
    top: 0;
    left: 0;
    content: counter(ol) '.';
  }

  /* Unordered */
  .typography ul li {
    padding-left: 1.4em;
  }

  .typography ul li::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '•';
  }
}

/* Headings */
/* ============================================ */

@layer components {
  .typography h1,
  .typography h2 {
    @apply text-black;
    @apply text-xl;
    @apply font-medium;

    &:not(:first-child) {
      @apply mt-9;
    }

    &:not(:last-child) {
      @apply mb-2;
    }
  }

  .typography h3,
  .typography h4,
  .typography h5,
  .typography h6 {
    @apply text-black;
    @apply font-medium;

    &:not(:first-child) {
      @apply mt-9;
    }

    &:not(:last-child) {
      @apply mb-1;
    }
  }
}
